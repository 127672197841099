<template>
  <div class="analytics-navigation-section select-none">
    <div v-for="(item, index) in items" :key="index">
      <v-tooltip v-if="item.inProgress" right>
        <template #activator="{ props }">
          <div
            class="analytics-navigation-section__link analytics-navigation-section__link--default-cursor"
            v-bind="props"
          >
            <Icon
              :name="item.icon"
              class="analytics-navigation-section__icon"
              :class="item.iconClass"
              size="20"
            ></Icon>

            <div class="analytics-navigation-section__label">
              {{ item.label }}
            </div>
          </div>
        </template>

        Coming soon
      </v-tooltip>

      <div v-else-if="item.children">
        <ExpansionPanel
          class="analytics-navigation-section__dropdown"
          header-padding="12px"
          :arrow-options="{
            secondary: true,
            side: 'right',
            direction: 'horizontal',
          }"
          :initial-open="(item?.children || []).some(isOpenedPage)"
          no-actions
        >
          <template #title>
            <div class="flex items-center">
              <Icon
                :name="item.icon"
                class="analytics-navigation-section__icon"
                :class="item.iconClass"
                size="20"
              ></Icon>

              <div class="analytics-navigation-section__label">
                {{ item.label }}
              </div>
            </div>
          </template>

          <template #content>
            <div
              v-if="item.children && item.children.length"
              class="analytics-navigation-section__dropdown-content"
            >
              <div class="analytics-navigation-section__dropdown-divider" />

              <NuxtLink
                v-for="child in item.children"
                :key="child.to"
                class="analytics-navigation-section__link"
                exact-path
                active-class=""
                :to="child.to"
              >
                <Icon
                  :name="child.icon"
                  class="analytics-navigation-section__icon"
                  size="20"
                ></Icon>

                <div class="analytics-navigation-section__label">
                  {{ child.label }}
                </div>
              </NuxtLink>
              <div class="analytics-navigation-section__dropdown-divider" />
            </div>
          </template>
        </ExpansionPanel>
      </div>

      <a v-else-if="item.link" :href="item.link" class="analytics-navigation-section__link">
        <Icon
          :name="item.icon"
          class="analytics-navigation-section__icon"
          :class="item.iconClass"
          size="20"
        ></Icon>

        <div class="analytics-navigation-section__label">
          {{ item.label }}
        </div></a
      >

      <nuxt-link
        v-else
        class="analytics-navigation-section__link"
        exact-path
        active-class=""
        :to="item.to"
      >
        <Icon
          :name="item.icon"
          class="analytics-navigation-section__icon"
          :class="item.iconClass"
          size="20"
        ></Icon>

        <div class="analytics-navigation-section__label">
          {{ item.label }}
        </div>
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts">
import ExpansionPanel from '~/shared/ui/expansion-panel/expansion-panel.vue';
import { NavItem } from '~/shared/ui/app/app-navigation.inventory';

export default {
  name: 'NavigationSidebarSection',

  components: { ExpansionPanel },

  props: {
    items: {
      type: Array as PropType<NavItem[]>,
      required: true,
    },
  },

  methods: {
    isOpenedPage({ to }: NavItem) {
      return !!to && this.$route.path.includes(to);
    },
  },
};
</script>

<style lang="scss">
@import '../../../app/style/vars/navigation';

.analytics-navigation-section {
  &__link {
    display: flex;
    align-items: center;
    padding: 12px 0 12px 12px;
    margin: 0 15px;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    transition: $collapseNavigationTransition;

    &--default-cursor {
      cursor: default;
    }

    svg {
      flex-shrink: 0;
    }

    &:hover {
      background: theme('colors.blue.8');
    }

    &.router-link-exact-active {
      background: theme('colors.blue.8');
    }

    &.router-link-exact-active svg {
      color: #{theme('colors.blue.100')} !important;
    }
  }

  &__icon {
    margin-right: 12px;
    color: theme('colors.grey.100') !important;
  }

  &__label {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: theme('colors.grey.100');
    transition: $collapseNavigationTransition;
  }

  &__dropdown {
    .expansion-panel__header {
      @extend .analytics-navigation-section__link;
    }

    &--active {
      background: theme('colors.blue.8');
    }
  }

  &__dropdown-content {
    position: relative;
    padding: 16px 0;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 16px;
      height: 16px;
      border-bottom-left-radius: 4px;
      border-style: solid;
      border-bottom: 1px theme('colors.grey-blue.32') solid;
      border-left: 1px theme('colors.grey-blue.32') solid;
      border-top: none;
      border-right: none;
      top: 8px;
      left: 29px;
      transform: rotate(135deg);
      background: white;
    }
  }

  &__dropdown-divider {
    background: theme('colors.grey-blue.32');
    height: 1px;
    margin: 0 -32px;

    &:first-child {
      margin-bottom: 16px;
    }

    &:last-child {
      margin-top: 16px;
    }
  }
}
</style>
