<template>
  <div>
    <ContainerBase>
      <AppHeaderLogo />

      <MessageFullScreen>
        <template #illustration>
          <IllustrationWaiting />
        </template>

        <template #title>Data is not ready</template>
        <template #subtitle
          >We are preparing the data for display on the page — they will be available soon
          <br />
          <div class="text-body-s text-grey-blue-100 mt-2">
            When the data is ready, we will inform you by e-mail
          </div>
        </template>
        <template #footer>
          <NuxtLink to="/logout">
            <BtnBase color="blue" btn-style="flat" width="240px"> Logout </BtnBase>
          </NuxtLink>
        </template>
      </MessageFullScreen>
    </ContainerBase>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ContainerBase from '~/shared/ui/container/container-base.vue';
import AppHeaderLogo from '~/shared/ui/app/app-header-logo.vue';
import MessageFullScreen from '~/shared/ui/message/message-full-screen.vue';
import IllustrationWaiting from '~/shared/ui/illustration/illustration-waiting.vue';
import BtnBase from '~/shared/ui/btn/btn-base.vue';

export default defineComponent({
  name: 'AppStatePreparingData',
  components: {
    BtnBase,
    IllustrationWaiting,
    MessageFullScreen,
    AppHeaderLogo,
    ContainerBase,
  },
});
</script>

<style lang="scss"></style>
