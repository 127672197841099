<template>
  <VApp class="app-layout">
    <AppStateForbidden v-if="forbidden" />

    <AppStatePreparingData v-else-if="noAvailableDates" />

    <template v-else>
      <AppNavigationSidebar class="app-layout__navigation" />

      <VMain class="app-layout__wrap">
        <NuxtPage class="app-layout__page" />
      </VMain>
    </template>
  </VApp>
</template>

<script setup lang="ts">
import AppStateForbidden from '~/shared/ui/app/app-state-forbidden.vue';
import AppNavigationSidebar from '~/shared/ui/app/app-navigation-sidebar.vue';
import AppStatePreparingData from '~/shared/ui/app/app-state-preparing-data.vue';
import { computed } from 'vue';

defineComponent({
  name: 'LayoutDashboard',
});

const forbidden = computed(() => {
  const app = useNuxtApp();
  return !app.$user.session.hasAnalyticsAccess;
});

const noAvailableDates = computed(() => {
  const app = useNuxtApp();
  return app.$integration.noAvailableDates;
});

onBeforeMount(() => {
  if (forbidden.value) {
    // window.history.pushState('Forbidden', 'Forbidden', '/');
  }
});
</script>

<style lang="scss">
@import '@/app/style/vars/navigation.scss';

.app-layout {
  &__wrap {
    --v-layout-left: #{$expandedNavigationWidth} !important;
    background: theme('colors.grey.4');
    animation: 2s content theme('transitionTimingFunction.DEFAULT');
  }

  &__page {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &__navigation {
    width: $expandedNavigationWidth;
    animation: 2s navigation theme('transitionTimingFunction.DEFAULT');

    &:not(:hover) {
      @media (max-width: $collapseNavigationBreakpoint) {
        width: $collapsedNavigationWidth;
        .analytics-navigation {
          &__company__logo {
            path {
              opacity: 0;
            }
          }
        }

        .analytics-navigation-section {
          &__link {
            width: 44px !important;
          }

          &__label {
            opacity: 0;
          }
        }
      }
    }

    /*&:hover {
        @media (max-width: $collapseNavigationBreakpoint) {
          width: $expandedNavigationWidth;
        }
      }*/
  }

  .v-main {
    height: 100%;
    padding-bottom: 0 !important;

    @media (max-width: #{$collapseNavigationBreakpoint}) {
      --v-layout-left: #{$collapsedNavigationWidth} !important;
    }
  }
}

@keyframes navigation {
  0% {
    transform: translateX(-24px) scaleY(1.05);
    opacity: 0;
  }

  35% {
    transform: translateX(-24px) scaleX(1.05);
    opacity: 0;
  }

  100% {
    // transform: translateX(-32px);
    // opacity: 0;
  }
}

@keyframes content {
  0% {
    transform: translateY(32px) scaleX(0.98);
    opacity: 0;
  }

  35% {
    transform: translateY(32px) scaleX(0.98);
    opacity: 0;
  }

  100% {
    // transform: translateX(-32px);
    // opacity: 0;
  }
}
</style>
